.spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 45px;
  height: 9px;
  margin: -13px auto;
  text-align: center;

  div {
    width: 9px;
    height: 9px;
    border-radius: 100%;
    display: inline-block;
    animation: bouncedelay 1.4s infinite ease-in-out;

    &:nth-child(1) {
      animation-delay: -0.32s;
    }
    &:nth-child(2) {
      animation-delay: -0.16s;
    }
  }
}

#loading-bubble {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 45px;
  height: 37.6px;
  min-width: 73px;
  border-radius: 10px;
  position: relative;
}

#html-container {
  & > * {
    font-weight: 500 !important;
    font-size: 0.875rem !important; /* 14px */
    line-height: 1.25rem !important; /* 20px */
    font-family: "Roboto", sans-serif !important;
  }
}

.signatureCanvas {
  //border: 1px solid black;
  width: 100%;
  min-height: 500px;
}


@keyframes bouncedelay {
  0%,
  80%,
  100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
}
.Card--Response {
  padding: 0;
  border-radius: 20px !important;
  background: #fff;
}
.Chat--Body {
  &::-webkit-scrollbar {
    width: 6px; /* Width of the scrollbar */
  }
  &::-webkit-scrollbar-thumb {
    background-color: #bbb6b6; /* Color of the scrollbar handle */
    border-radius: 4px; /* Border radius of the scrollbar handle */
  }
}
