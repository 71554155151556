.CustomDrawer .CustomDrawer--Content::-webkit-scrollbar {
  width: 4px; /* Width of the scrollbar */
}
.CustomDrawer .CustomDrawer--Content::-webkit-scrollbar-thumb {
  background-color: #bbb6b6; /* Color of the scrollbar handle */
  border-radius: 4px; /* Border radius of the scrollbar handle */
}

.overflow-y-auto::-webkit-scrollbar {
  width: 8px; /* Width of the scrollbar */
}
.overflow-y-auto::-webkit-scrollbar-thumb {
  background-color: #d1cece; /* Color of the scrollbar handle */
  border-radius: 4px; /* Border radius of the scrollbar handle */
}/*# sourceMappingURL=Drawer.css.map */