.languagelist--container {
  &::-webkit-scrollbar {
    height: 8px;
    width: 5px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #9a9999;
    border-radius: 10px;
  }
}
