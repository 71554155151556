@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 222.2 84% 4.9%;
    --card: 0 0% 100%;
    --card-foreground: 222.2 84% 4.9%;
    --popover: 0 0% 100%;
    --popover-foreground: 222.2 84% 4.9%;
    --primary: 267 28% 48%; /* Updated primary color */
    --primary-foreground: 210 40% 98%;
    --secondary: 210 40% 96.1%;
    --secondary-foreground: 267 28% 48%; /* Updated to match new primary color */
    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;
    --accent: 210 40% 96.1%;
    --accent-foreground: 267 28% 48%; /* Updated to match new primary color */
    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 210 40% 98%;
    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;
    --ring: 222.2 84% 4.9%;
    --radius: 0.5rem;
    --chart-1: 12 76% 61%;
    --chart-2: 173 58% 39%;
    --chart-3: 197 37% 24%;
    --chart-4: 43 74% 66%;
    --chart-5: 27 87% 67%;
  }

  .dark {
    --background: 222.2 84% 4.9%;
    --foreground: 210 40% 98%;
    --card: 222.2 84% 4.9%;
    --card-foreground: 210 40% 98%;
    --popover: 222.2 84% 4.9%;
    --popover-foreground: 210 40% 98%;
    --primary: 263, 63%, 50%;
    --primary-foreground: 267 28% 48%; /* Updated to match new primary color */
    --secondary: 217.2 32.6% 17.5%;
    --secondary-foreground: 210 40% 98%;
    --muted: 217.2 32.6% 17.5%;
    --muted-foreground: 215 20.2% 65.1%;
    --accent: 217.2 32.6% 17.5%;
    --accent-foreground: 210 40% 98%;
    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 210 40% 98%;
    --border: 217.2 32.6% 17.5%;
    --input: 217.2 32.6% 17.5%;
    --ring: 212.7 26.8% 83.9%;
    --chart-1: 220 70% 50%;
    --chart-2: 160 60% 45%;
    --chart-3: 30 80% 55%;
    --chart-4: 280 65% 60%;
    --chart-5: 340 75% 55%;
  }
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
  }
} /*# sourceMappingURL=globals.css.map */

@layer utilities {
  .gradient-yellow-orange {
    background-image: linear-gradient(to right, #ffd029, #f79009);
  }
}

body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.rtl {
  direction: rtl;
}
.ltr {
  direction: ltr;
}

textarea {
  /* Width of the scrollbar */
  &::-webkit-scrollbar {
    width: 0;
    // @media (min-width: 640px) {
    //   width: 0;
    // }
  }
  // &::-webkit-scrollbar-thumb {
  //   background-color: #8b8b8b; /* Color of the scrollbar handle */
  //   border-radius: 4px; /* Border radius of the scrollbar handle */
  // }
}

.suggestionScroll {
  &::-webkit-scrollbar {
    width: 4px; /* Width of the scrollbar */
    height: 4px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #8b8b8b; /* Color of the scrollbar handle */
    border-radius: 4px; /* Border radius of the scrollbar handle */
    height: 2px;
  }
}

.server-response {

  a {
    color: #3a8ff6 !important;
    text-decoration: underline;
    
  }
}

// .border--beam::before {
//   background: conic-gradient(
//     rgb(233, 55, 120) 0deg,
//     rgb(219, 38, 222) 0deg,
//     transparent 90deg
//   );
// }
